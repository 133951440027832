@import "../Base.scss";

.header_image {
  margin: 0 10px 5px 10px;
  border-radius: $round;
  background-color: #808080;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-bottom: 1rem;
  img {
    border-radius: $round;
    object-fit: cover; // 이미지 안찌그러지면서 꽉차게
    width: 100%;
    height: 100%;
    height: 14rem;
    filter: brightness(50%);
  }
}

.project_title {
  position: absolute;
  color: $lightestGrey;
  font-weight: $semiBold;
  font-size: $fontMl;
  padding: 1rem;
  // bottom: 2rem;
}

.project_status {
  position: absolute;
  // position: absolute;
  color: $lightestGrey;
  font-weight: $normal;
  font-size: $fontSs;
  padding: 0.3rem 0.7rem;
  border-radius: 1rem;
  // background-color: darkcyan;
  bottom: 3rem;
}
