@import "../Base.scss";


.login__wrap {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin : 4rem;
	.login__header {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-top: 2rem;
		margin-bottom: 7rem;
		.title-text {
			letter-spacing: -0.1rem;
			color: $mainColor;
			font-size: 5rem;
			font-weight: 1000;
		  }
		.title-icon {
			position: relative;
			bottom: -0.18rem;
			font-size: 7rem;
			text-align: center;
			color: $black;
		  }
	}

	.signin__text{
		margin: 3rem 0rem 2rem 0rem;
		font-size: 1.5rem;
		color: $grey;
	}

	// .login__seperator {
	// 	display: flex;
	// 	flex-direction: row;
	// 	width: 40%;
	// 	margin: 2rem 0rem 3rem 0rem;

	// 	hr {
	// 		width: 100%;
	// 		color: black;
	// 		height: 0.2rem;
	// 	}
	// 	.or__text {
	// 		display: block;
	// 		padding: 0.2rem;
	// 		font-size: 2rem;
	// 		text-align: center;
	// 	}
	// }

	.intra__login__btn {
		padding: 1rem 2rem 1rem 2rem;
		border: 1px solid $grey;
		border-radius: $round;
		margin-bottom: 2rem;
		background-color: #01B0B2;
		box-shadow: $shadow;

		.login__btn__tag {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}
		.btn-icon {
			font-size: 4rem;
			margin-right: 1rem;
			color: black;
		}
		.btn-text {
			font-size: 1.5rem;
			color: white;
		}
	}

	.google__login__btn {
		padding: 1rem 2.5rem 1rem 2rem;
		border: 1px solid $grey;
		border-radius: $round;
		margin-bottom: 1rem;
		background-color: white;
		box-shadow: $shadow;
		text-overflow: ellipsis;

		.login__btn__tag {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}
		.btn-icon {
			font-size: 4rem;
			margin-right: 1rem;
		}
		.btn-text {
			font-size: 1.5rem;
			color: black;
		}
	}

	.google__text{
		font-size: 1rem;
		color: $grey;
		margin-bottom: 5rem;
	}
  }
