// 모든 페이지 배경 색
$bodyColor: #f9f9f9;
//
$mainColor: #a7bc5b;
$black: #2c2c34;
$darkGrey: #565656;
$grey: #8e8e93;
$lessGrey: #a8a8a8;
$lightGrey: #c4c4c4;
$lightestGrey: #e5e5e5;
$slightGrey: #efefef;
$pointRed: #ff6864;
$pointBlue: #5bbcb6;
$pointYellow: #ffb648;
//font-weight 기본:400
$bold: 800;
$semiBold: 700;
$normal: 600;
$regular: 500;
$thin: 400;
//페이지 전체 margin 기준
$fontXXl: 3rem; //36px
$fontXl: 2.5rem; //30px
$fontLg: 2rem; //24px
$fontMl: 1.8rem;
$fontMd: 1.5rem; //18px
$fontMs: 1.3rem;
$fontSm: 1rem;
$fontSs: 0.85rem; //14px ?
$shadowDeep: 0rem 0.3rem 0.3rem 0rem rgba(0, 0, 0, 0.25);
$shadow: 0rem 0.3rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
$shadowLight: 0rem 0.2rem 0.5rem 0rem rgba(0, 0, 0, 0.05);
$shadowAll: 0 0 0 9999px rgba(0, 0, 0, 0.6);

$round: 0.7rem; //border-radius 통일
// $semiRound: 0.4rem; // button 등 작은 요소들

//페이지 상단 연두색 box 사이즈 (프로필 배너 등)

$minWidth: 28rem;
// iphon 5
$iphone5Width: 27rem;
$galaxyFoldWidth: 24rem;
